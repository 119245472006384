@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');   

p, h1, button {
    font-family: 'Poppins', sans-serif;
}


#cookies-msg {
    width: 80%;
    max-width: 1300px;
    background: var(--color-neutral-40);
    position: fixed;
    bottom: 5px;
    left: 50%;
    justify-content: center;
    transform: translate(-50%);
    border-radius: 15px;
    z-index: 900;
    box-shadow: 0px 3px 10px #464646;
    overflow-y: auto;
    max-height: 90vh;
    opacity: 0; /* Start hidden */
    visibility: hidden; /* Start not visible */
    transition: opacity 1s ease, visibility 1s ease; /* Smooth transition for opacity and visibility */
}

#cookies-msg.aparecer {
    opacity: 1;
    visibility: visible; /* Make visible when appearing */
    transition: opacity 1s ease-in-out; /* Smooth transition for appearing */
}

.desaparecer {
    opacity: 0 !important; /* Ensure the element fades out */
    visibility: hidden !important; /* Ensure the element is not visible after fading out */
    transition: opacity 1s ease-in-out, visibility 1s ease-in-out; /* Ensure the transition is applied to both properties */
    pointer-events: none; /* Disable interaction with the element */
    
}



.cookies-msg .cookies-txt h1 {
    text-align: start; /* Centraliza o texto horizontalmente */
}
.cookies-msg a {
    color: rgb(73, 128, 147);
}
.cookies-msg .cookies-txt {
    align-items: center;
    text-align: justify;
    padding: 20px;
}
.cookies-msg .cookies-txt .content {
    display: flex;
    align-items: center;
    text-align: justify;
}
.cookies-txt .content .btns {
    display: block;
}

.cookies-msg .cookies-btn {
    margin-left: 30px;
    margin-bottom: 20px;
}

.cookies-msg .cookies-btn #acc {
    background-color: rgb(38, 175, 221);
    color: var(--color-neutral-40);
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    padding: 10px 50px;
    cursor: pointer;
    transition: .2s;
}

.cookies-msg .cookies-btn button:hover {
    transform: scale(0.9);
}

@media screen and (max-width: 1005px) {
    .cookies-txt h2 {
        font-size: 1.8rem;
    }

    p {
        margin-block: 10px;
    }

    .cookies-msg .cookies-txt .content {
        display: block;
        align-items: center;
        text-align: justify;
    }

    .cookies-txt .content .btns {
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .cookies-msg .cookies-btn #acc {
        padding: 10px 300px;
    }

    .cookies-msg .cookies-btn {
        margin-left: 0;
        margin-block: 20px;
    }
}

@media screen and (max-width: 840px) {
    .cookies-msg .cookies-btn #acc {
        padding: 10px 200px;
    }
}

@media screen and (max-width: 678px) {
    .cookies-txt h2 {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 617px) {
    .cookies-msg .cookies-btn #acc {
        padding: 10px 150px;
    }
}

@media screen and (max-width: 530px) {
    .cookies-txt .content .btns {
        display: block;
        text-align: center;
        justify-content: space-evenly;
    }

    .cookies-msg .cookies-btn #acc {
        padding: 10px 130px;
    }
}

@media screen and (max-width: 450px) {
    .cookies-txt h2 {
        font-size: 0.9rem;
    }

    p{
        font-size: 0.8rem;
    }

    .cookies-msg .cookies-btn #acc {
        padding: 7px 30px;
        font-size: 12px;
    }
    
}

@media screen and (max-width: 382px) {
    .cookies-txt h2 {
        font-size: 0.8rem;
    }

    p{
        font-size: 0.7rem;
    }

    .cookies-msg .cookies-btn #acc {
        padding: 6px 25px;
        font-size: 12px;
    }
}
