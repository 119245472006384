    #clients {
        margin-top: 60px;
        margin-bottom: 150px;
        text-align: center; /* Centraliza o conteúdo */
        padding: 20px 0; /* Ajuste o padding conforme necessário */
    }

    #clients h2 {
        font-size: 3.7rem;
        margin-bottom: 50px;
    }

    #clients span {
        font-size: 3.7rem;
        margin-bottom: 50px;
        color: var(--color-primary-10);

    }



    #clients .divsor {
        position: relative;
        padding-inline: 50px;
        width: 60%;
        margin: 0 auto;
      
    }

    
    
    #clients .client {
        
        cursor: pointer;
        margin-block: 20px;
     
        border-right: 3px solid #ccc;
        display: flex;
        justify-content: center; /* Centers content horizontally */
        align-items: center; /* Centers content vertically */
        height: 200px;
        padding-top: 50px;
        
        box-sizing: border-box; /* Makes sure padding doesn't affect the final size */
    }
    
    #clients .client img {
        max-width: 90%; /* Allows image to be up to 100% of the .client div's width */
        max-height: 60%; /* Fixes the image's maximum height */
        display: block;
        margin: auto; /* Centers the image in the available space */
 
    }

   


    #clients .client:hover {
        box-shadow: 10px 0 15px -5px rgba(0, 0, 0, 0.7),
                    -10px 0 15px -5px rgba(0, 0, 0, 0.7);
        
    }
    

    #clients button {
        margin-top: 30px;
        background-color: #5cd9ff; /* Cor do botão ajustada conforme a sua paleta de cores */
        color: #fff;
        border: none;
        padding: 10px 80px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: bold;
        
      }
      
      #clients  button:hover {
        box-shadow: 0 0 20px var(--color-primary-10);
        background-color: #49b4d6; /* Cor mais escura no hover */
      }
    
    
    @media screen and (max-width: 1630px) {
        #clients h2, #clients span {
            font-size: 3.2rem; /* Slightly smaller font size for larger text */
        }
        #clients .divsor {
            width: 80%; /* Increased width for better use of space */
        }
    }
    
    @media screen and (max-width: 1030px) {
        #clients h2, #clients span {
            font-size: 2.8rem; /* Further reduce font size for medium screens */
        }
        
        #clients .client {
        
           
            border: none;
        }
        #clients button {
            padding: 10px 60px; /* Adjust padding to fit smaller screens */
        }
    }
    
    @media screen and (max-width: 770px) {
        
        #clients h2, #clients span {
            font-size: 2.4rem; /* Smaller font sizes to fit smaller screens */
            margin: 0;
        }
        
        #clients button {
            font-size: 1.2rem; /* Smaller font size for button text */
            padding: 8px 40px; /* Smaller padding for button */
        }

        #clients .client {
            height: 100px;
            padding-top: 25px;
        }

        #clients .client:hover {
            box-shadow: none;
            
        }

        #clients  button:hover {
            box-shadow: none;
            background-color: #5cd9ff; /* Cor mais escura no hover */
        }    
    }
    
    @media screen and (max-width: 480px) {
        #clients h2, #clients span {
            font-size: 2rem; /* Even smaller font sizes for very small devices */
        }
        #clients {
            margin-top: 10px;
           
        }
        #clients button {
            width: 100%; /* Full width button */
            padding: 10px; /* Uniform padding */
            font-size: 1rem; /* Further reduce font size */
        }
    }
    
    @media screen and (max-width: 320px) {
        #clients h2, #clients span {
            font-size: 1.8rem; /* Minimum size for readability */
        }
        #clients .divsor {
            padding-inline: 20px; /* Reduce padding for very small screens */
        }
       
    }
    