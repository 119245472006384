.services-section {
    margin-top: 150px;
    background-color: #fff; /* Mudança para o fundo branco */
    color: #000; /* Texto preto para contraste */
    padding: 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Três colunas de igual tamanho */
  
    gap: 20px; /* Espaço entre os elementos da grid */
    align-items: center;
    padding-block: 50px;

  }

.services-content {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

}


.services-content-text{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 40px auto 0 auto;
  justify-items: center;

}
  


  .services-content-text h2{
    font-size: 3.5rem;
    font-weight: bold;
    
    
  }
  

  .services-content-text span {
    font-size: 3.7rem;
    font-weight: bold;
    color: var(--color-primary-10);
    margin-bottom: 1rem;

  }
  

  .services-content-text p {
    font-size: 1.3rem;
    margin-bottom : 2rem;
    padding-right: 60px;
  }
  
   .services-content-text button {
    background-color: #5cd9ff; /* Cor do botão ajustada conforme a sua paleta de cores */
    color: #fff;
    border: none;
    padding: 10px 40px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .services-content-text button:hover {
    box-shadow: 0 0 20px var(--color-primary-10);
    background-color: #49b4d6; /* Cor mais escura no hover */
  }

  .services-section .services-cards {
    margin-top: -150px; /* Move os cards para cima em 100px */
}


.services-section .services-card.second {
  display: none;
}
  /* Estilo para os cartões de serviço */
  .service-card {
    background-color: white; /* Cor de fundo claro para os cards */
    border-radius: 10px;
    width: 90%;
    margin-inline: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Sombra sutil */
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Alinha o conteúdo textual para o fundo */
    transition: 1s;
    
  }

 
  

  .service-card p {
    color: #333; /* Cor para o título e texto */
    padding: 20px 20px 10px 20px;
    height: 140px;
  }

  .service-card h3 {
    display: flex;
    margin: 0; /* Remove margens para garantir o alinhamento */
    width: 100%; /* Ocupa a largura total do card */
    padding: 10px 10px 10px 20px;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: var(--color-primary-20);
    text-align: center;
   
  }

  .service-card h3 .bi-card{
    padding: 0px 0px 0px 0px;
    font-size: 1.7rem;
    
   
  }

 

  .service-card img {
    padding-left: 20px;
    width: 70px;
    vertical-align: middle;
  }

  

  
  
  

  /* Estilo específico para a imagem de fundo nos cartões */
  .img-card1 {
    background-image: url("../../utils/img/cards/dashboard.png");
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-position: center;
    border-radius: 10px 10px 0 0;
    margin-bottom: 20px; /* Espaço entre a imagem e o texto do cartão */
    height: 210px; /* Altura fixa para as imagens dos cartões */
  }

  .img-card2 {
    background-image: url("../../utils/img/cards/sites.png");
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-position: center calc(10% - 80px);
    border-radius: 10px 10px 0 0;
    margin-bottom: 20px; /* Espaço entre a imagem e o texto do cartão */
    height: 210px; /* Altura fixa para as imagens dos cartões */
  }

  .img-card3 {
    background-image: url("../../utils/img/cards/sup-ti.png");
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-position: center calc(20% - 50px);
    border-radius: 10px 10px 0 0;
    margin-bottom: 20px; /* Espaço entre a imagem e o texto do cartão */
    height: 210px; /* Altura fixa para as imagens dos cartões */
  }

  .img-card4 {
    background-image: url("../../utils/img/cards/infra.png");
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-position: center calc(10% - 50px);
    border-radius: 10px 10px 0 0;
    margin-bottom: 20px; /* Espaço entre a imagem e o texto do cartão */
    height: 210px; /* Altura fixa para as imagens dos cartões */
  }

  .img-card5 {
    background-image: url("../../utils/img/Microsoft/expertise.png");
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-position: center calc(10% - 50px);
    border-radius: 10px 10px 0 0;
    margin-bottom: 20px; /* Espaço entre a imagem e o texto do cartão */
    height: 210px; /* Altura fixa para as imagens dos cartões */
  }


  
  .service-card:hover {
    transform: translateY(-10px);
    background: linear-gradient(to bottom, #062e33, #000);
    box-shadow: 0  20px 40px rgba(0, 0, 0, 0.9);
    cursor: pointer;
    

    h3{
        color:var(--color-neutral-40);
        text-shadow: 0 0 20px var(--color-primary-10);
    }
    p {
        color: var(--color-neutral-30);
    }
    span {
        color: var(--color-neutral-40);
    }

  }
  


.services-section .services-cards3 {
  display: none;
}

.services-section .services-cards4 {
  display: none;
}


  @media screen and (max-width: 1630px) {
    .service-card p {
      
      margin-bottom: 40px;
    }

    .services-section p{
      font-size: 0.98rem;
      height: 95px;
    }
    .services-section .services-content .services-content-text p {
      font-size: 1rem;
      height: 90px;
    }


    .service-card h3{
      padding: 7px;
    }

    .img-card1,.img-card2,.img-card3,.img-card4,.img-card5{
      height: 180px;
    }

    .services-content-text p {
      margin-bottom: 1rem;
    }
  
  }

  @media screen and (max-width: 1530px) {
    .service-card h3 .bi-card{

      
      width: 50%;
     
    }
    .img-card1,.img-card2,.img-card3,.img-card4,.img-card5{
      height: 150px;
    }
  }

  @media screen and (max-width: 1400px) {
    .services-section p{
      font-size: 0.85rem;
      height: 90px;
    }

    .services-content-text p {
      margin-bottom: 2.7rem;
    }

    .img-card1,.img-card2,.img-card3,.img-card4,.img-card5{
      height: 140px;
    }
  }


  @media screen and (max-width: 1270px) {

    .img-card1 {
      height: 180px;
    }

    .img-card2 {

      height: 180px;

    }

    .img-card3 {
      height: 180px;

    }

    .img-card4 {
      height: 180px;

    }

    .img-card5 {
      height: 180px;

    }

    .service-card p {
      height: 150px;
      margin-bottom: 5px;
    }
  
    .services-section {
      grid-template-columns: repeat(2, 1fr); /* Two columns for medium-sized devices */
      padding: 50px 20px; /* Reduced padding */
  }

  .services-content-text, .services-cards {
      margin-top: 0; /* Resetting negative margins for a cleaner layout */
  }

  .services-content-text p, .service-card p {
      font-size: 1rem; /* Smaller font size for readability */
  }

  .services-cards2 {
    display: none;/* Resetting negative margins for a cleaner layout */
}

.services-section .services-cards3 {
  display: block;
}

.services-section .services-cards4 {
  display: block;
}
.services-content-text {
  margin-top: -100px;
  
}

.service-card h3 .bi-card{

      
  width: 70%;
  
 
}
  
}
  
@media screen and (max-width: 1035px) {
  .service-card h3 .bi-card{
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 880px) {
  .service-card h3 .bi-card{
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 770px) {
    .services-section {
        width: 90%;
        margin: 130px auto;
        display: grid;
        grid-template-columns: 1fr; /* One column for smaller devices */
        justify-content: center;
        padding: 30px 15px; /* Further reduced padding */
        margin-top: 0px;
    }

    .services-content-text h2, .services-content-text span {
        
        font-size: 2.5rem; /* Smaller headings to fit smaller screens */
    }

    

    .services-content-text button {
      margin-bottom: 30px;
    }

    .services-content{
      margin-bottom: 260px;
    }


    .service-card {
        width: 100%; /* Full width for the card in a single column layout */
        
    }

    .services-section {
      gap: 0px;
    }
    /*.service-card:hover {
        transform: scale(1.02); 
    }*/

 
    .service-card h3 .bi-card{
      font-size: 2rem;
    }

    .services-content-text button:hover {
      box-shadow: none;
      background-color: #5cd9ff; /* Cor mais escura no hover */
    }

    .service-card:hover {
      transform: translateY(0px);
      

      
  
    }
    .service-card p {
      height: auto;
    }

    .service-card {
      transform: translateY(0px);
      background: linear-gradient(to bottom, #062e33, #000);
      box-shadow: 0  20px 40px rgba(0, 0, 0, 0.9);
      cursor: pointer;
      
  
      h3{
          color:var(--color-neutral-40);
          text-shadow: 0 0 20px var(--color-primary-10);
      }
      p {
          color: var(--color-neutral-30);
      }
      span {
          color: var(--color-neutral-40);
      }
  
    }

    .services-content {
      display: flex;
      flex-direction: column;
      justify-content:space-around;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .services-content-text {
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 0px;
    }

    .services-content-text p {
      padding-right: 0px;
      margin-block: 0px;
    }

    .services-section .services-cards {
      margin-top: 0px; /* Move os cards para cima em 100px */
  }
    
}

@media screen and (max-width: 480px) {
    .services-content-text h2, .services-content-text span, .service-card h3 {
        font-size: 2rem; /* Even smaller text for very small devices */
    }

    .services-content-text p, .service-card-text p {
        font-size: 0.9rem; /* Smaller paragraph text */
    }

    .services-content-text button, .service-card-text button {
        font-size: 0.8rem; 
        padding: 8px 16px; 
        margin-bottom: 60px;
    }

    .img-card2 {
      height: 210px;
      background-position: center calc(10% - 40px); ;
    }

    .service-card {
      margin: auto;
      margin-bottom: 60px;
    }
}

@media screen and (max-width: 400px) {
    

    .services-content-text p, .service-card p {
        font-size: 0.8rem; /* Minimum font size for readability on very small screens */
    }

    .services-content-text button, .service-card button {
        padding: 6px 12px; /* Reduced button size for small screens */
    }

    .services-section .services-content .services-content-text p {

      margin-bottom: 20px;
    }

    

    .services-section{
      margin-bottom: 5px;
    }
    
}
