
:root {
    --color-neutral-0: #0e0c0c;
    --color-neutral-10: #171717;
    --color-neutral-30: #a8a29e;
    --color-neutral-40: #f5f5f5;
    

}


footer{
    
    width: 100%;
    color: var(--color-neutral-40);
}


.footer-link {

    text-decoration: none;
}

#footer_content {
    background-color: var(--color-neutral-10);
    display: grid;
    grid-template-columns: repeat(4,1fr);
    padding: 3rem 3.5rem;
    
}

#footer_contacts h1 {
    margin-bottom: 0.75rem;

}

#footer_social_media{
    display: flex;
    gap: 2rem;
    margin-top: 1.5rem;
    margin-left: 0.5rem;
}

.footer-logo {
    width: 220px;
}

#footer_social_media .footer-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    color: var(--color-neutral-40);
    border-radius: 50%;
    transition: all 0.4s;
    
}

#footer_social_media .footer-link:hover {
    opacity: 0.8;

}

#footer_social_media .footer-link .i {
    font-size: 1.25rem;
}

#instagram {
    background: linear-gradient(#7f37c9, #ff2992, #ff9807);
}

#facebook {
    background-color: #4267b3;
}

#whatsapp {
    background-color: #25d366;
}
.footer-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    list-style: none;
    margin: 0rem 1rem 1rem 1rem;
}

.footer-list .footer-link{
    color: var(--color-neutral-30);
    transition: all 0.4s;
}


.footer-list .footer-link:hover {
    color: #fff
}

#footer_copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-neutral-0);
    font-size: 0.9rem;
    padding: 1.5rem;
    font-weight: 100;
}

@media screen and (max-width: 768px) {
    #footer_content{
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
}

@media screen and (max-width: 426px) {
    #footer_content{
        grid-template-columns: repeat(1, 1fr);
        padding: 3rem 2rem;
    }
    #footer_contacts{
        margin-left: 1rem;
        margin-bottom: 0.5rem;
    }
}