@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');   

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth
}

body {
    font-family: 'Poppins', sans-serif !important;
    font-size: 18px;
    height: 180vh;
}

img {
    user-select: none;
}

p {
    font-size: 18px;
}

