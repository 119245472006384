@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');   

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth
    
}

img {
    user-select: none;
}


.container-main .title {
    color: var(--color-primary-10);
    font-weight: 900;
    font-size: 3.2rem;
    text-shadow: 0 0 50px rgba(255, 255, 255, 1);
}

.container-main .title-principal {
    color: var(--color-primary-10);
    font-weight: 900;
    font-size: 2.7rem;
    text-shadow: 0 0 50px rgba(255, 255, 255, 1);
}

p {
    font-size: 18px;
 
}


/* Estilos para as setas de navegação */    


    .slick-prev,
    .slick-next {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border-radius: 50%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.3s ease;
    }

    .slick-prev:hover,
    .slick-next:hover {
    color: rgba(255, 255, 255, 0.7);
    }

    .slick-prev {
    left: 20px;
    }

    .slick-next {
    right: 20px;
    }



.background-blur {
    background-image: url('../../utils/img/datacenter.jpeg');
    filter: blur(5px);
    background-repeat: no-repeat;
    background-size: cover;
    
    background-position: bottom;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.background-blur-contato {
    background-image: url('../../utils/img/datacenter2.jpeg');
    filter: blur(6px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}


main section.containers, main section.container-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


main section.containers {
    position: relative;
    background: rgb(0, 0, 0);
    height: 100vh;
    z-index: 1;
    gap: 2%;
}


main section.container-main {
    gap: 20px;
}




main section.container-main h1 {
    font-size: 3em;
    padding-bottom: 5px;
    border-bottom: 3px solid white;
    width: 70%;   
}


main section.container-main p {
    color: var(--color-neutral-40);
    width: 70%;
    font-size: 1.5rem;
    font-weight:500;
}





main section.containers button{
    margin-top: 20px;
    padding: 15px 40px;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Poppin', sans-serif;
    background-color: var(--color-primary-20);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

main section.containers button:hover {
    color: var(--color-neutral-40);
    background-color: var(--color-primary-10);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
}

main section.containers button a {
    color: var(--color-neutral-40);
    font-weight: bold;
}



main {
    
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
}


.slider-divider {
    height: 30px; /* Ajuste a altura conforme necessário */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(255, 255, 255, 1));
    margin-bottom: 100px;
  }
  
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-up {
    animation: fadeInUp 0.8s ease-out forwards;
}

@media screen  and (max-width: 1810px) {
    .container-main .title-principal {
        
        font-size: 2.5rem;
        
    }

   
}

@media screen  and (max-width: 1684px) {
    .container-main .title-principal {
        
        font-size: 2.3rem;
        
    }

   
}

@media screen  and (max-width: 1541px) {
    .container-main .title-principal {
        
        font-size: 2.1rem;
        
    }

   
}


@media screen  and (max-width: 1422px) {
    main section.container-main .title {
        font-size: 2.7rem;
    }
    .container-main .title-principal {
        
        font-size: 2rem;
        
    }
    main section.container-main p {
        font-size: 20px;
    }
    main section.containers button{
        padding: 12px;
        font-size: 1.4rem;
    }

   
}

@media screen  and (max-width: 1350px) {
   
    .container-main .title-principal {
        
        font-size: 1.9rem;
        
    }
   
   
}

@media screen  and (max-width: 1276px) {
   
    .container-main .title-principal {
        
        font-size: 1.7rem;
        
    }
    main section.containers button{
        padding: 12px;
        font-size: 1.3rem;
    }
   
   
}

@media screen  and (max-width: 1200px) {
   
    main section.container-main .title {
        font-size: 2.1rem;
    }
    .container-main .title-principal {
        
        font-size: 1.6rem;
        
    }
    main section.container-main p {
        font-size: 18px;
    }
    main section.containers button{
        padding: 11px;
        font-size: 1.2rem;
    }
   
   
}
@media (max-width: 770px) {
    main section.containers button:hover {
        color: var(--color-neutral-40);
        background-color: var(--color-primary-20);
        box-shadow: none;
    }
}




@media (max-width: 500px) {
    .slick-prev,
    .slick-next {
        width: 35px;
    }
    .slick-prev {
        left: 1px;
    }
    
    .slick-next {
        right: 1px;
    }


    main section.container-main .title {
        font-size: 1.35rem;
        text-shadow: none;
    }
    .container-main .title-principal {
        
        font-size: 1rem;
        text-shadow: none;
        
    }
    main section.container-main p {
        font-size: 13px;
    }
    main section.containers button{
        padding: 8px;
        font-size: 0.8rem;
    }

    
}

@media (max-width: 290px) {
   
}