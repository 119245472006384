
:root {
    --color-neutral-0: #0e0c0c;
    --color-neutral-10: #171717;
    --color-neutral-30: #a8a29e;
    --color-neutral-40: #f5f5f5;
}



header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    background-color: rgb(23,23,23,0.95);
    box-shadow: 0px 3px 10px #464646;
    transition:  background-color 1000ms linear;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 2rem;
    z-index: 1001 !important; /* Use !important para garantir que esta regra seja aplicada */
    
}







.logo {
    display: flex;
    align-items: center;
}

.logo h1 {
    color: var(--color-neutral-40)
}





.nav-list ul {
    display: flex;
    justify-content: center; /* Mantém o conteúdo centralizado */
    align-items: center;
    text-align: center;
    list-style: none;
     /* Alinha o conteúdo na parte inferior */
}

.nav-list li.nav-item a {
    
    padding-block: 10px;
}

.nav-list li.nav-item a.servico {
    padding-inline: 0px;
}

.nav-item {
    margin: 5px 13px;
    
}

.nav-link:hover {
    color: var(--color-primary-10); /* Altera a cor do texto para branca */
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.7); /* Adiciona um brilho atrás do texto */
}

.nav-link {
    text-decoration: none;
    font-size: 1.15rem;
    color: var(--color-neutral-40);
   
    display: block;
    font-weight: bold;
}

.nav-link-sub:hover {
    color: var(--color-primary-10); /* Altera a cor do texto para branca */
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.7); /* Adiciona um brilho atrás do texto */
}

.nav-link-sub {
    text-decoration: none;
    font-size: 0.95rem;
    color: var(--color-neutral-40);
    font-weight: bold;
    display: block;
}


header img {
    width: 200px;
}



.social_media:hover {
    opacity: 0.9;
    color: var(--color-primary-30);
}

.social_media {
    font-size: 1.75rem;
}

.social_media .logov2 {
    font-size: 1.75rem;
}

.mobile-menu-icon {
    display: none;
}
.nav-bar.shadow {
    box-shadow: none;
    transition: top 0.5s ease;
}



.dropdown-menu{
    position: absolute;
    border-radius: 10px;
    width: 190px;
    left: auto; /* Reset to default value */
    right: 390px;
    display: none;
    box-shadow: 0px 3px 20px #464646;
    background-color: var(--color-neutral-40);
    
}

.dropdown-menu a {
    padding-inline: 5px;
    color: var(--color-neutral-0);
}

.dropdown:hover .dropdown-menu {
    display: block;
    
}



.nav-item.dropdown:hover .nav-link.servico {
    color: var(--color-primary-10);
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
}



.dropdown-menu a:hover {
    color: var(--color-neutral-40);
    background: var(--color-primary-20);
    box-shadow: 0px 3px 10px #464646;
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
    border-radius: 10px;
}


.mobile-menu {
    display: block;
    background: var(--color-neutral-10);
    box-shadow: 0px 3px 10px #464646;
    position: fixed;
    top: -100%; /* Inicia o menu móvel acima da tela */
    width: 100%;
    transition: top 0.5s ease; /* Adiciona uma transição suave */
}

.mobile-menu.open {
    top: 92px; /* Move o menu móvel para o topo da página com margem para o nav-bar */
}



@media screen and (max-width: 1600px) {
    header img {
        width: 170px;
    }
    .nav-link {
        font-size: 1rem;
    }
    .nav-link-sub {
        font-size: 0.85rem;
    }
    .social_media {
        font-size: 1.5rem;
    }
    .nav-bar {
        padding: 0.7rem 2rem;
    
    }
    .dropdown-menu {
        right: 370px;
        width: 160px;
    }

}



@media screen and (max-width: 1112px) {
    .nav-bar {
        padding: 1.5rem 3rem;
        background-color: var(--color-neutral-10);
    }
    
    .nav-item {
        display: none;
    }
    .mobile-menu-icon {
        display: block;
    }
    .nav-bar .mobile-menu-icon {
        display: block; 
        position: relative;
        width: 50px;
        height: 50px;
        margin-left: 20px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
    }
    .nav-bar .mobile-menu-icon span {
        position: absolute;
        width: 40px;
        height: 4px;
        background: var(--color-neutral-40);
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
    }
    .nav-bar .mobile-menu-icon span:nth-child(1){
        transform: translateY(-11px);
    }
    .nav-bar .mobile-menu-icon span:nth-child(2){
        transform: translateY(11px);
    }
    .nav-bar .mobile-menu-icon.open span:nth-child(1) {
        transform: translateY(0px) rotate(45deg);
    }
    .nav-bar .mobile-menu-icon.open span:nth-child(2) {
        transform: translateY(0px) rotate(315deg);
    }
    .nav-bar .mobile-menu-icon.open span:nth-child(3) {
        transform: translateX(60px); 
    }
    
    .mobile-menu ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
        padding-inline: 1rem;
    }
    .mobile-menu .nav-item {
        display: block;
        padding-top: 1.2rem;
        padding-bottom: 0.5rem;
        margin-left: 0; 
        text-align: center; 
    }
    .open {
        display: block;
    }

    


}

@media screen and (max-width: 768px) {
    header img {
        width: 210px;
    }
}

@media screen and (max-width: 470px) {
    .nav-bar {
        padding: 1.5rem 1rem;
    }
    header img {
        width: 190px;
    }
}


