
.contact-content-page {
  display: flex;
  justify-content: center;
  width: 90%;
  max-width: 1600px;
  height: 850px;
  margin: 100px auto 150px auto ;
  padding: 50px 0;
  background-color: #fff; /* background of the content area */
}

.contact-info-page, .contact-form-page {
  flex-basis: 50%;
  padding: 2rem;
}

.redes-sociais-form p{
  font-weight: bold;
  padding-bottom: 5px;

}

.social {
  font-size: 2rem;
  color: white;
}

.contact-info-page {

  background-image: url("../../utils/img/CONTATO/contact-form.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  color: white;   
  border-radius: 20px 0 0 20px; /* Rounded corners on the left */
  padding: 3.5rem 3.5rem 3.5rem 3.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
  height: 94%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
 
  justify-content: center;
  gap: 20px;
  
}

.contact-form-page {

  background: white;
  border-radius: 20px; /* Rounded corners on the right */
  padding: 2rem;
  margin: auto 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 700px;
  height: 100%;

}
.contact-info-page h1 {
  font-size: 2.6rem;
  color: var(--color-neutral-40);
  font-weight: bold;
}
.contact-form-page h2 {
  font-size: 3rem;
  color: var(--color-primary-20); /* Purple text for headers */

}

.contact-info-page p {
  font-size: 1.25rem;
  color: var(--color-neutral-40);
}

.how-contact {
  margin-top: 20px;
}


.form-space {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start; /* Alinha o texto à esquerda */
  margin-inline: auto;
  margin-bottom: 7px;
}



.contact-form-page input, .contact-form-page textarea {
  
  justify-content: center; /* Alinha o conteúdo do label à esquerda */
  width: 90%;
  font-size: 1.2rem;
  padding: 10px;
  border-radius: 5px;
  background: rgba(92, 217, 255, 0.1); /* azul com 30% de transparência */
  border: 1px solid var(--color-primary-20);
}


.contact-form-page textarea {
  max-width:  90%;
  min-width:  90%;
  min-height: 150px;
  max-height: 150px;
  resize: none;
}

.contact-form-page #botao-form button{
  padding: 10px 20px;

  width: 90%;
 
  background-color: var(--color-primary-20); /* Purple background for the button */
  color: white;
  border: none;
  border-radius: 5px 15px 5px 15px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.contact-form-page #botao-form button:hover{
  box-shadow: 0 0 20px var(--color-primary-20);
  background-color: var(--color-primary-10); /* A darker purple for hover state */
}
.gif{
  display: none;
  width: 20px;
  margin-left: 10px;
  vertical-align: middle;
}
.contact-form-page #botao-form {

  .disabled-style{
    button{
      background-color: rgba(92, 217, 255, 0.4);
    }
  
    button:hover {
      box-shadow: none;
      background-color: rgba(92, 217, 255, 0.4);
    }
  
  
   
  
    .gif{
      display: inline-block;
    }
  }

 
  
}


.description-form {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #757575;
}


.popup {
  display: block;
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: 1s ease;
}

.nao-aparece{
  display: none;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: 1s ease;
}


.popup-content {
  background: #fff;
  padding: 20px;
  max-width: 500px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin: auto; /* Center horizontally */
  position: relative; /* Required for centering vertically */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for vertical centering */
}

.popup-content p {
  margin-top: 40px;
  margin-bottom: 40px; /* Adjust the spacing between paragraphs */
  font-size: 1.1rem;
  font-weight: bold;
}



.popup-content img {
  width: 200px; /* Adjust the width of the image */
  height: auto; /* Maintain the aspect ratio */

}


.popup-header img {
  display: block;
  margin: 0 auto 15px;
}


.popup-footer button {
  background-color: #5cd9ff; /* Cor do botão ajustada conforme a sua paleta de cores */
  color: #fff;
  border: none;
  padding: 10px 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.popup-footer button:hover {
  box-shadow: 0 0 20px var(--color-primary-10);
  background-color: #49b4d6; /* Cor mais escura no hover */
}

.wrong {
  
  width: 90%;
  margin: 0 auto;
  
  color: red;
  font-size: 0.9rem;
}


  
  @media screen and (max-width: 1630px) {
    .contact-content-page {
      display: flex;
      justify-content: center;
      width: 90%;
      max-width: 1500px;
      
      height: 710px;
      margin: 100px auto 150px auto ;
      padding: 50px 0;
      background-color: #fff; /* background of the content area */
    }
    
    .contact-form-page {
  
      background: white;
      border-radius: 20px; /* Rounded corners on the right */
      padding: 2.4rem;
      margin: auto 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      max-width: 700px;
      height: 100%;
  
    }
   

    .contact-form-page h2 {
      font-size: 2.9rem;
      color: var(--color-primary-20); /* Purple text for headers */
  
    }
  
    
  
  
    
  
  
    
    .contact-form-page input, .contact-form-page textarea {
    
      font-size: 1rem;
      padding: 10px;
      
      border-radius: 5px;
      background: rgba(92, 217, 255, 0.1); /* azul com 30% de transparência */
      border: 1px solid var(--color-primary-20);
    }

 
  
    .contact-form-page textarea {
    
      min-height: 100px;
      max-height: 100px;
 
     
    }
    
  


  
  }

  @media (max-width: 1200px) {
    .contact-form-page h2 {
      font-size: 2.4rem;
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .contact-content-page {
      flex-direction: column;
      width: 80%;
      padding: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
      border-radius: 20px;
      height: auto;
      
    }


  
    .contact-info-page, .contact-form-page   {
      flex-basis: 100%;
      max-width: 100%;
      margin: auto;
      border-radius: 20px; /* Rounded corners all around for mobile */
      margin-bottom: 1rem;
      box-shadow: none;
      border-radius: 0;
      
    }

    .contact-info-page{
        margin-bottom: 0;
        border-radius: 20px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
        margin-bottom: 10px;
        padding: 2.5rem 3rem;
    }

    .contact-form-page{
        border-radius: 0 0 20px 20px;
    }

   
    .wrong {
      font-size: 0.7rem;
    }
  }
  /* Responsive adjustments for 770px */
@media screen and (max-width: 620px) {
  .contact-info-page h1 {
    font-size: 2rem;
    
  }
  .contact-info-page p {
    font-size: 1.2rem;
  }
  .contact-form-page input, .contact-form-page textarea{
    
    font-size: 1rem;
  }
  .popup-content {
    width: 87%;
  }

  .form-space {
    margin-bottom: 7px;
  }

  .contact-form-page #botao-form button:hover{
    box-shadow: none;
    background-color: var(--color-primary-20); /* A darker purple for hover state */
  }
  .popup-footer button:hover {
    box-shadow: none;
    background-color: #5cd9ff; /* Cor mais escura no hover */
  }
}

@media screen and (max-width: 560px){
  .contact-form-page h2 {
    font-size: 2rem;
  }
  .description-form {

    font-size: 0.8rem;
  }
  
}

/* Responsive adjustments for 480px */
@media screen and (max-width: 480px) {
  .form-space {
    margin-bottom: 5px;
  }
 
  .contact-content-page{
    width: 90%;
  }
  .contact-info-page h3 {
    font-size: 1.2rem;
    
  }
  .contact-info-page h1 {
    font-size: 1.5rem;
    
  }
  .contact-info-page p {
    font-size: 1rem;
  }
  .contact-form-page input, .contact-form-page textarea{
    
    font-size: 0.9rem;
  }
  .social {
    font-size: 2rem;
  }

  .contact-form-page h2 {
    font-size: 1.8rem;
  }
  .description-form {

    font-size: 0.75rem;
  }

  .contact-info-page {
    padding: 1.5rem
  }
  .contact-form-page {
    margin-top: 20px;
    padding: 0.8rem
  }

  .redes-sociais-form {
    justify-content: center;
    text-align: center;
    color: white;
  }

  .redes-sociais-form a {
    color: white;
  }
}

/* Responsive adjustments for 320px */
@media screen and (max-width: 320px) {
  .contact-info-page h1 {
    font-size: 1.5rem;
    
  }
}




