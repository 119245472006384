@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');   


:root {
    --color-neutral-0: #0e0c0c;
    --color-neutral-10: #171717;
    --color-neutral-30: #a8a29e;
    --color-neutral-40: #f5f5f5;
    --color-primary-10: #5cd9ff;
    --color-primary-20: #50b8d7;
    --color-primary-30: hsl(186, 100%, 67%);
    --color-primary-40: #002a2e;
    --color-primary-50: #47c5eb;
    --color-primary-60: #2bb5ffeb;
}


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth
}

body {
    font-family: 'Inter', sans-serif !important;
    font-size: 18px;
}

img {
    user-select: none;
}

p {
    font-size: 18px;
}

a {
    text-decoration: none;
    color: #000;
}

/* Altera a cor da barra de rolagem */
::-webkit-scrollbar {
    width: 12px; /* Largura da barra de rolagem */
}

/* Altera a cor do fundo da barra de rolagem */
::-webkit-scrollbar-track {
    background-color: #000; /* Cor de fundo */
}

/* Altera a cor da barra de rolagem */
::-webkit-scrollbar-thumb {
    background-color: #333; /* Cor da barra de rolagem */
    border-radius: 6px; /* Borda arredondada */
}

/* Altera a cor da barra de rolagem ao passar o mouse sobre ela */
::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Cor da barra de rolagem ao passar o mouse */
}

/* Para navegadores WebKit (Chrome, Safari, etc.) */
@media screen and (max-width: 1112px) {
    /* Altera a cor da barra de rolagem */
    ::-webkit-scrollbar {
        width: 12px; /* Largura da barra de rolagem */
    }

    /* Altera a cor do fundo da barra de rolagem */
    ::-webkit-scrollbar-track {
        background-color: #000; /* Cor de fundo */
    }

    /* Altera a cor da barra de rolagem */
    ::-webkit-scrollbar-thumb {
        background-color: #333; /* Cor da barra de rolagem */
        border-radius: 6px; /* Borda arredondada */
    }

    /* Altera a cor da barra de rolagem ao passar o mouse sobre ela */
    ::-webkit-scrollbar-thumb:hover {
        background-color: #555; /* Cor da barra de rolagem ao passar o mouse */
    }

    /* Define o estilo da barra de rolagem para dispositivos móveis */
    body {
        scrollbar-width: thin; /* Largura da barra de rolagem */
        scrollbar-color: #333 #000; /* Cor da barra de rolagem e do fundo */
    }
}

@media screen and (max-width: 768px) {
    body {
        background-color: var(--color-neutral-10);
    
    }
    main {
        background-color: var(--color-neutral-40);

    }
}

