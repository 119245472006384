#about {
    max-width: 1800px;
    margin: 0 auto;
    margin-block: 50px;
    
  }

  .container-about{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, #062e33, #000);
    color: var(--color-neutral-40);
    padding: 50px;
    max-width: 95%;
    margin: 0 auto;
    box-shadow: 0  20px 40px var(--color-primary-40, 0.9);
    border-radius: 10px;
  }

  
  
  .about-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .about-img-wrapper {
    flex-basis: 40%;
    position: relative; /* Para posicionar absolutamente os itens de serviço em relação a este wrapper */
    margin-top: -100px; /* Levanta a imagem */
  }

  .v-middle {
    vertical-align: middle;
    
  }
  
  .about-img {
    width: 500px; /* Reduz o tamanho da imagem */
    border-radius: 7px;
    display: block;
    margin-bottom: px;
  }
  
  .services-list {
    font-size: 15px;
    
    list-style-type: none; /* Remove os marcadores de lista */
    position: absolute; /* Posiciona a lista sobre a imagem */
    right: 0; /* Alinha à direita do wrapper da imagem */
    top: 63%; /* Inicia no meio do wrapper da imagem */
  
    transform: translateX(-30%); /* Centraliza verticalmente em relação ao topo do wrapper da imagem */
    background: linear-gradient(to right, #007BFF, #00DBDE); /* Fundo semitransparente */
    padding: 25px 45px; /* Espaçamento interno */
    border-radius: 5px; /* Arredonda apenas os cantos à direita */
    margin-right: -50px; /* Faz a lista se sobrepor à imagem */
  }
  
  .service-item {
    color: var(--color-neutral-40);
    padding: 5px 10px;
    /* Adiciona um separador entre os itens */
  }

    .about-content {
        flex-basis: 60%;
        padding: 20px;
    }

    .about-title {
        font-size: 1em;
        color: var(--color-primary-20); /* Cor primária ajustada conforme variáveis */
    }

    #about h1 {
        font-size: 2em;
        margin-bottom: 20px;
        color: var(--color-neutral-40); /* Cor de texto mais clara */
    }

    #about p {
        font-size: 1em;
        margin-bottom: 20px;
        color: var(--color-neutral-30); /* Cor de texto intermediária */
    }

    #about p span {
        font-size: 1em;
        margin-bottom: 20px;
        color: var(--color-neutral-40); /* Cor de texto intermediária */
    }

    #about button a {
      font-weight: bold;
      color: var(--color-neutral-40); /* Cor de texto intermediária */
  }
    

    #about button{
        background: linear-gradient(to right, #00DBDE, #007BFF); /* Cor do botão ajustada */
        border: none;
        color: var(--color-neutral-40);
        padding: 15px 40px;
        text-align: center;
        border-radius: 5px;
        text-decoration: none;
        display: inline-blo ck;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        margin-bottom: 20px;
    }

    #about button:hover {
        background-color: var(--color-primary-30); /* Efeito hover ajustado para uma cor primária mais clara */
        color: var(--color-neutral-40);
        box-shadow: 0 4px 20px rgba(255, 255, 255, 0.7);
    }


    @media screen and (max-width: 1630px) {
      .about-img {
        width: 80%; /* Reduz o tamanho da imagem */
        
      }

      .container-about{
        padding: 30px 50px;
        
      }


    }


    @media screen and (max-width: 1400px) {
      .about-img {
        width: 80%; /* Reduz o tamanho da imagem */
        
      }

      .about-img-wrapper {
        flex-basis: 60%;
        position: relative; /* Para posicionar absolutamente os itens de serviço em relação a este wrapper */
        margin-top: -50px; /* Levanta a imagem */
        margin-left: 20px;
      }

      .services-list{
        padding: 19px 25px;
      }
      
      #about p {
        font-size: 0.9rem;
      }

      #about h1 {
        font-size: 1.6rem;
        margin-bottom: 20px;
        color: var(--color-neutral-40); /* Cor de texto mais clara */
    }

    #about button{
      padding: 10px 30px;
      
    }

    .container-about{
      padding: 30px;
      
    }

    .about-container{
      margin-top: 0px;
    }

    .about-content {
      padding: 0px;
    }

    }

    @media screen and (max-width: 1230px) {
      .about-img-wrapper {
        margin-left: 0px;
        flex-basis: 70%;
        position: relative; /* Para posicionar absolutamente os itens de serviço em relação a este wrapper */
        margin-top: -100px; /* Levanta a imagem */
      }

      .services-list{
        padding: 20px 35px;
      }

      .container-about{
        padding: 50px;
        
      }
  
      .about-container{
        margin-top: 40px;
      }
  
      .about-content {
        padding: 20px;
      }

    }


    
    @media screen and (max-width: 1030px) {

      .about-container{
        flex-direction: column;
      }
      
    
      .about-img-wrapper, .about-content {
        
        flex-basis: 90%; /* Each takes full width of the container */
        margin-top: 20px; /* Adds some space above for visual separation */
      }
    
      .services-list {
        width: 90%;
        
        border-radius: 0 0 10px 10px;
        position: static; /* Resets to normal flow, no longer absolute */
        transform: translateX(0%); /* Neutralizes horizontal shift */
        right: auto; /* Removes right alignment to default to natural flow */
        top: auto; /* Removes top alignment to default to natural flow */
        margin-right: 0;
        margin: 0 auto; 
      }
    
      .about-img {
        width: 90%; /* Full width within the container */
        border-radius: 10px 10px 0px 0px;
        margin: 0 auto; /* Centers the image within the block */
      }
    }
    
    @media screen and (max-width: 920px) {
      .about-img-wrapper, .about-content {
          padding: 15px; /* Reduced padding for tighter spaces */
      }
  
      .about-img {
          width: 90%; /* Slightly smaller to fit mobile screens better */
      }
  
      .services-list {
          width: 90%;
          margin: auto;
          padding: 15px 25px; /* Smaller padding */
          font-size: 14px; /* Smaller font size for better readability */
      }
  
      #about h1, #about p, .about-title {
          font-size: 0.9em; /* Smaller font size for headlines and paragraphs */
      }
  }
  
  @media screen and (max-width: 770px) {
      .about-img-wrapper, .about-content {
          padding: 10px; /* Even smaller padding for very tight spaces */
      }
  
      .about-img {
          width: 85%; /* Further reduction to ensure no horizontal scrolling */
      }

  
      .services-list {
        
          width: 85%;
          margin: auto;
          font-size: 13px; /* Further reduction for better fit */
      }

      #about button {
        margin-bottom: 50px;
      }


  
      #about button {
          padding: 10px 30px; /* Smaller buttons */
          font-size: 15px; /* Smaller button text */
      }
  
      #about h1, #about p, .about-title {
          font-size: 0.75em; /* Further reduce text size */
      }

      #about button:hover {
        background-color: var(--color-primary-30); /* Efeito hover ajustado para uma cor primária mais clara */
        color: var(--color-neutral-40);
        box-shadow: none;
    }

    #about {
      margin-bottom: 80px;
    }
  }
  
  @media screen and (max-width: 480px) {
      .container-about {
          padding: 20px; /* Reduce outer padding */
      }
  
      .about-img-wrapper, .about-content {
          flex-direction: column; /* Stack elements vertically for narrow screens */
      }
  
      .about-img {
          width: 100%; /* Full width */
          
      }
  
      .services-list {
          width: 100%;
          
          font-size: 12px; /* Smaller text in service list */
      }
  
      #about button {
          width: auto; /* Auto width for flexibility */
          padding: 8px 20px; /* Smaller padding */
      }
  }
  
  @media screen and (max-width: 320px) {
        .container-about, .about-img-wrapper, .about-content {
            padding: 10px; /* Minimal padding */
        }
    
        .services-list {
            font-size: 11px; /* Very small text size for extremely small devices */
        }
    
        #about h1, #about p, .about-title, .service-item {
            font-size: 0.7em; /* Reduce text size to fit small screens */
        }
    
        #about button {
            padding: 6px 18px; /* Small button padding */
        }
    }
  