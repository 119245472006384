.buttonSolution {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .buttonSolution button {
    background-color: var(--color-primary-10);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  .buttonSolution a {
    padding-left: 10px;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    gap: 10px;
  }
  
  .bg-arrow-services {
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    box-shadow: 0 2px 10px var(--color-primary-10);
  }
  
  .arrow-btn-services {
    color: var(--color-primary-20);
    width: 22px;
    height: 22px;
    transition: transform 0.5s ease;
  }
  
  .buttonSolution button:hover {
    background-color: var(--color-primary-20);
    box-shadow: 0 0 20px var(--color-primary-10);
  }
  
  .buttonSolution button:hover .arrow-btn-services {
    transform: rotate(-360deg);
  }
  
  /* Responsive Adjustments */
  @media screen and (max-width: 1630px) {
    .buttonSolution button {
      padding: 8px;
    }
    .bg-arrow-services{
      width: 30px;
      height: 30px;
      padding: 7px;
    }
    
    .buttonSolution a {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 1030px) {
    .buttonSolution {
      margin-right: 15px;
      margin-bottom: 15px;
    }
    .buttonSolution button {
      padding: 6px;
    }
  }
  
  @media screen and (max-width: 770px) {
    .buttonSolution button {
      font-size: 0.9rem;
      padding: 5px;
    }
    .buttonSolution a {
      font-size: 1rem;
    }

    .buttonSolution button:hover {
      background-color: var(--color-primary-10);
      box-shadow: none;
    }
    
    .buttonSolution button:hover .arrow-btn-services {
      transform: rotate(0deg);
    }
  }
  
  @media screen and (max-width: 480px) {
    .buttonSolution {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .buttonSolution button {
      border-radius: 40px; /* Slightly less rounded corners */
    }
    .bg-arrow-services {
      width: 36px;
      height: 36px;
    }

   

  }
  
  @media screen and (max-width: 320px) {
    .buttonSolution button {
      font-size: 0.8rem;
      padding: 3px;
    }
    .buttonSolution a {
      font-size: 0.8rem;
    }
    .bg-arrow-services {
      padding: 8px;
      width: 34px;
      height: 34px;
    }
  }
  