#service-description2 {
    background-color: var(--color-neutral-10);
    margin-block: 100px;
}

#service-description2 h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 100px;
}

#service-description2 h2 span {
    color: var(--color-primary-10);
}


.container-licenciamneto {
    
    width: 85%;
    margin: 0 auto;
    padding: 10px;
    padding-block: 100px;
    margin-block: 1px;
    color: white;
}


@media screen and (max-width: 550px) {
    
    #service-description2 h2{
        font-size: 150%;
    }
}


@media screen and (max-width: 376px) {

    #service-description h2{
        font-size: 120%;
    }

}