.container-include {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 30px;
    width: 100%;
    margin: 30px auto 20px auto;
    box-shadow: 0 0 10px var(--color-primary-20);
    border-radius: 10px;
    min-height: 120px;
    height: auto;

}

.icon-include {
    font-size: 4rem;
    color: var(--color-primary-10); 
    display: flex;  
    justify-content: center;  
    align-items: center;
}

.text-include {
    font-size: 1.3rem;
    text-align: justify;
}

.text-include span {
    font-weight: bold;
}

.titulo-include {
    color: var(--color-primary-20);  
    font-size: 1.4rem; 
    font-weight: bold;
}


@media screen and (max-width: 1630px) {
   
    
    .icon-include {
        font-size: 4rem;
   
    }
    
    .text-include {
        font-size: 1.25rem;
        text-align: justify;
    }
 
    
    .titulo-include {
        
        font-size: 1.35rem; 
      
    }
}

@media screen and (max-width: 1030px) {
    .icon-include {
        font-size: 4rem;
   
    }
    
    .text-include {
        font-size: 1.1rem;
        text-align: justify;
    }
 
    
    .titulo-include {
        
        font-size: 1.2rem; 
      
    }    
}

@media screen and (max-width: 770px) {
    .icon-include {
        font-size: 4rem;
   
    }
    
    .text-include {
        font-size: 1rem;
        text-align: justify;
    }
 
    
    .titulo-include {
        
        font-size: 1.1rem; 
      
    }    
}


@media screen and (max-width: 480px) {
    .container-include{
        flex-direction: column;
    }

    .icon-include {
        font-size: 6rem;
   
    }
}


