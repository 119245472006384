.header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative; /* Isso faz com que a posição absoluta de SobreBg seja relativa a este contêiner */
    height: 59vh; /* Ajuste conforme necessário para definir a altura do contêiner */
    width: 100%; /* Isso garante que o contêiner ocupe toda a largura */
    background: rgb(2,0,36);
    box-shadow: 0 0 10px var(--color-neutral-10);
    z-index: 1;
    gap: 2%;
}


.container-header {
    width: 100%; /* Ajusta a largura para ocupar todo o espaço disponível */
    padding-left: 50px;
    margin-block: auto;
    text-shadow: 0 0 4px var(--color-neutral-40);
    
}

.container-header p {
   
    font-size: 1.25rem;
    color: white;
    margin-bottom: 20px;
}

.title-header {
    
    text-shadow: 0 0 4px var(--color-neutral-40);
    font-size: 3rem;
    color: var(--color-primary-10);
    display: flex; /* Usa flexbox para alinhar os itens na linha */
    align-items: center; /* Centraliza verticalmente os itens dentro do contêiner */
    gap: 20px; /* Adiciona um espaço entre o título e o ícone */
    margin-top: 30px;
    margin-bottom: 3px;
}

.title-header-title-ajust {
    
    text-shadow: 0 0 4px var(--color-neutral-40);
    font-size: 3rem;
    color: var(--color-primary-10);
    display: flex; /* Usa flexbox para alinhar os itens na linha */
    align-items: center; /* Centraliza verticalmente os itens dentro do contêiner */
    gap: 20px; /* Adiciona um espaço entre o título e o ícone */
    margin-top: 30px;
    margin-bottom: 3px;
}

.bg-icon {
    
    background-color: var(--color-neutral-10);
    padding: 1px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    box-shadow: 0 2px 10px var(--color-primary-10);
    vertical-align: middle;
    
}

.icon-header {
     width: 100%;
    vertical-align: middle;
    width: 38px;
    height: 38px;
}

.ajust {
    margin-left: 9px;
    margin-top: 7px;
}

.diminuir {
    padding: 5px;
}

.container-header button {
    background-color: var(--color-primary-20); /* Exemplo de cor, ajuste conforme necessário */
    color: white;
    border: none;
    padding: 10px 12px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    display: flex; /* Adiciona flexbox para alinhar itens na linha */
    justify-content: center; /* Centraliza os itens horizontalmente */
    align-items: center; /* Centraliza os itens verticalmente */
    gap: 10px; /* Adiciona espaço entre o texto e o ícone */
}

.container-header button a {
    padding-left: 10px;
    font-size: 1rem;
    font-weight: bold;
    display: flex; /* Assegura que a tag <a> também utilize flexbox */
    align-items: center; /* Centraliza os itens verticalmente dentro da tag <a> */
    color: var(--color-neutral-40); /* Define a cor do texto dentro do botão */
    text-decoration: none; /* Remove o sublinhado do link */
    gap: 10px; /* Espaço entre o texto e o ícone */
}

.bg-arrow {
    background-color: var(--color-neutral-40);
    padding: 2px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    box-shadow: 0 2px 10px var(--color-primary-10);
}

.arrow-btn {
    color: var(--color-primary-20);
    width: 22px;
    height: 22px;
    transition: transform 0.5s ease;
}
    
  .container-header button:hover {
    background-color: var(--color-primary-50); /* Cor mais escura no hover */
    box-shadow: 0 0 20px var(--color-primary-10);

    .arrow-btn {
        transform: rotate(-360deg); 
    }
  }


  @keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-up {
    animation: fadeInUp 0.8s ease-out forwards;
}

  

@media screen and (max-width: 1112px) {
    .container-header{
        padding-left: 50px;
    }

    .container-header p{
        margin: 0 0 10px 0;
    }

   
}

@media screen and (max-width: 770px) {
    .title-header {
        gap: 20px;
        font-size: 2.7rem;
    }
    .title-header-title-ajust{
        gap: 20px;
        font-size: 2.7rem;
    }
    .bg-icon{
        margin-right: auto;
    }

    .container-header button:hover {
        background-color: var(--color-primary-20); /* Cor mais escura no hover */
        box-shadow: none;
    
        .arrow-btn {
            transform: rotate(0deg); 
        }
      }
}

@media screen and (max-width: 610px) {
    .title-header {
        max-width: auto;
    }
    .title-header-title-ajust{
        font-size: 2rem;
    }
    .bg-icon{
        margin-right: auto;
       
    }
}



@media screen and (max-width: 550px) {
    .container-header{
        text-shadow: none;
        padding-left: 30px;
    }
    .title-header {
        margin-top: 60px;
        text-shadow: none;
        font-size: 2.5rem;
    }

    
    .container-header p{
        font-size: 1.1rem;
        margin-bottom: 20px;
    }
    .bg-icon {
        width: 47px;
        height: 47px;
    }

    .title-header-title-ajust{
        text-shadow: none;
        width: 250px;
        .bg-icon {
            width: 80px;
            height: 47px;
        }
    }

    .icon-header {
        width: 32px;
        height: 32px;
    }

    .ajust {
        margin-left: 7.3px;
        margin-top: 7px;
    }

    .container-header button {
        padding: 7px;
    }

    .container-header button a {
        font-size: 0.9rem;
    }

    .bg-arrow {
        width: 28px;
        height: 28px;
    }

    .arrow-btn{
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width: 410px){
    .container-header{
        padding-left: 20px;
    }
    .title-header {
        font-size: 2rem;
    }
    .container-header p{
        font-size: 1.05rem;
        margin-bottom: 20px;
    }
    
    .title-header-title-ajust{
        text-shadow: none;
        width: 290px;
        .bg-icon {
            width: 70px;
            height: 47px;
        }
    }

}

@media screen and (max-width: 340px) {
    .title-header-title-ajust{
        text-shadow: none;
        width: 260px;
        .bg-icon {
            width: 74px;
            height: 42px;
        }
    }
}