main {
    height: 100%;
}

#sobre .esquerda ul li  {
    list-style-type: none; 
    font-size: 1.4rem; 
    margin: 10px 0;
    text-align: justify;
    align-items: center;
}

#sobre #valores ul li {
    list-style-type: none; 
    font-size: 1.4rem; 
    margin: 10px 0;
    text-align: justify;
    align-items: center;
}

#texto-principal{
   width: 80%;
   margin: 0 auto;
   margin-top: 100px;
}

#texto-principal h1 {
    text-align: center;
    font-size: 3.5rem;
    margin-bottom: 20px;
}

#texto-principal h1 span{
    color: var(--color-primary-50);
}

#texto-principal p {
    
    font-size: 1.6rem;
   
    text-align: justify;

    margin-bottom: 100px;
}
#texto-principal p span{
    
    font-weight: bold;
}

#sobre .geral-about-us img {
    max-width: 750px;
    border: 3px dashed white;
    border-radius: 10px 40px;
    padding: 20px;
    box-sizing: border-box;
}



#sobre .geral-about-us .direita-img {

    border: 3px dashed black;

}

.esquerda {
    background: linear-gradient(to right, rgb(42, 42, 42), var(--color-neutral-10)) ;

    padding-bottom: 100px;
}

.esquerda h2 {
    padding-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    font-size: 3rem;
    color: var(--color-neutral-40);
}

.esquerda h2 span {
    color:var(--color-primary-50);
}

.esquerda .content-esquerda {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    gap: 100px;
}

.esquerda .esquerda-text{
    color: var(--color-neutral-40);
    font-size: 1.5rem;
    font-weight: 500;
    text-align: justify;
    vertical-align: middle;
  
    
}
.esquerda .esquerda-text span {
    font-weight: bold;
    color: var(--color-primary-20);
}
.primary {
    color: var(--color-primary-10);
}

#valores {
    padding-bottom: 150px;
}

.diamond {
    margin-left: 10px;
}
#valores h2 {
    padding-top: 100px;
    margin-bottom: 50px;
    text-align: center;
    font-size: 3rem;
   
}

#valores h2 span {
    color:var(--color-primary-50);
}

#valores .content-direita {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
}

#valores .direita-text{
    
    font-size: 1.8rem;
    
}


#valores .direita-text span{
    
    font-weight: bold;
    
}
.destaque-valores{
    
   color: var(--color-primary-20);
    
}

.esquerda-text-visao {
    color: var(--color-neutral-40);
    font-size: 1.8rem;
    font-weight: 500;
    text-align: justify;
    vertical-align: middle;
}
.esquerda-text-visao span{
    font-weight: bold;
    color: var(--color-primary-20);
}

#diferenciais {
    padding-top: 70px;
    margin-bottom: 200px;
}

#diferenciais h2 {
    padding-top: 50px;
    
    margin-bottom: 50px;
    text-align: center;
    font-size: 3rem;
   
}

#diferenciais h2 span {
    color:var(--color-primary-50);
}

#diferenciais .cards-sobre {
    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    flex-wrap: wrap;
}

#diferenciais .cards-sobre img {
    max-width: 170px;
}

#diferenciais .cards-sobre .card-sobre {
  
    min-width: 20%;
    max-width: 30%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;

 
    

}



#diferenciais .cards-sobre h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: center;
}


#diferenciais .cards-sobre p {
    width: 80%;
    font-size: 1.3rem;
    margin: 0 auto;
    text-align: justify;
}

.card-icon {
    color: var(--color-primary-10);
    font-size: 2rem;
}

.title-icon {
    color: var(--color-primary-10);
}

.menor {
    display: none;
}
#sobre #diferenciais .cards-sobre .card-sobre img {
    border-radius: 0px;
}

@media screen and (max-width: 1630px) {
    #sobre .esquerda ul li  {
       
        font-size: 1.1rem; 

    }
    
    #sobre #valores ul li {
   
        font-size: 1.1rem; 
    
    }
    
    #texto-principal{
     
       margin-top: 50px;
    }
    
    #texto-principal h1 {
        text-align: center;
        font-size: 2.8rem;
        margin-bottom: 20px;
    }
    
    #texto-principal h1 span{
        color: var(--color-primary-50);
    }
    
    #texto-principal p {
        
        font-size: 1.2rem;
       
        text-align: justify;
    
        margin-bottom: 50px;
    }

    
    #sobre .geral-about-us img {
        max-width: 450px;
     
    }
    
    
    #sobre .geral-about-us .direita-img {
    
        border: 3px dashed black;
    
    }
    
    .esquerda {

        padding-bottom: 50px;
    }
    
    .esquerda h2 {
        padding-top: 50px;
        margin-bottom: 0px;
        font-size: 2.8rem;
       
    }
    

    
    .esquerda .content-esquerda {
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3rem;
        gap: 100px;
    }
    
   

 
    #valores {
        padding-bottom: 100px;
    }
    
    .diamond {
        margin-left: 10px;
    }
    #valores h2 {
        padding-top: 50px;
        margin-bottom: 50px;
        font-size: 2.8rem;
       
    }
    





    
    .esquerda-text-visao {
        font-size: 1.2rem;
    }


    #diferenciais {
        padding-top: 50px;
        margin-bottom: 100px;
    }
    
    #diferenciais h2 {
        padding-top: 0px;
        
        margin-bottom: 20px;
        text-align: center;
        font-size: 2.8rem;
       
    }
    

    
    #diferenciais .cards-sobre .card-sobre {
  
        min-width: 20%;
        max-width: 30%;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
    
     
        
    
    }
    
    #diferenciais .cards-sobre img {
        max-width: 145px;

    }
    
    #diferenciais .cards-sobre .card-sobre {
      
        
        flex-direction: column;
        justify-content: space-between;
    
        border-radius: 20px;
        
    
    }
    
       
    #diferenciais .cards-sobre .card-sobre img {
      
        
        flex-direction: column;
        justify-content: space-between;
    
        border-radius: 0px;
        
    
    }
    
    
    #diferenciais .cards-sobre h3 {
        font-size: 1.3rem;
        margin-bottom: 10px;
 
    }
    
    
    #diferenciais .cards-sobre p {
        width: 90%;
        font-size: 1.1rem;
    
    }
    
 
}

@media screen and (max-width: 1160px) {
    
   

    
    #texto-principal h1 {
        text-align: center;
        font-size: 2.2rem;
        margin-bottom: 20px;
    }
    

    
    #texto-principal p {
        
        font-size: 1.1rem;
       
    }

    
    #sobre .geral-about-us img {
        max-width: 550px;
     
    }

    #diferenciais .cards-sobre img {
        max-width: 170px;
    }
    
 
    
    .menor {
        display: block;
    }
    .maior {
        display: none;
    }
    .esquerda .content-esquerda{
        flex-direction: column;
        gap: 40px;
    }

    .content-direita{
        flex-direction: column;
        
    }

    #valores .content-direita{
        gap: 40px;
    }

    .direita-text {
        padding: 0 3rem;
    }

    #diferenciais .cards-sobre .card-sobre{
        min-width: 30%;
        max-width: 40%;
    }
    
    

}

@media screen and (max-width: 770px) {
   

    #sobre .geral-about-us img {
        max-width: 400px;
     
    }

    #diferenciais .cards-sobre img {
        max-width: 140px;
    }
    
  
    #texto-principal{
     
       margin-top: 50px;
    }
    
    #texto-principal h1 {
    
        font-size: 1.7rem;
   
    }
    

    #texto-principal p {
        
        font-size: 1rem;
       
    
    }

    #diferenciais .cards-sobre h3 {
        font-size: 1.4rem;
   
    }
    
    
    #diferenciais .cards-sobre p {
        font-size: 1rem;
       
    }

    
}


@media screen and (max-width: 660px) {
    #diferenciais .cards-sobre {
    
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    #diferenciais .cards-sobre h3 {
        font-size: 1.3rem;
   
    }
    #diferenciais .cards-sobre .card-sobre {
  
        min-width: 80%;
        max-width: 80%;
    }
    #sobre .geral-about-us img {
        max-width: 300px;
        padding: 10px;
     
    }

    
    #diferenciais .cards-sobre img {
        max-width: 130px;
    }
    
}
@media screen and (max-width: 480px) {
    #sobre .esquerda ul li  {
       
        font-size: 0.9rem; 

    }
    
    #sobre #valores ul li {
   
        font-size: 0.9rem; 
    
    }

    .esquerda-text-visao {
        font-size: 0.9rem;
    }
    .esquerda h2 {
        font-size: 2.4rem;
    }
    #valores h2 {
        font-size: 2.4rem;
    }

    #diferenciais h2 {
        font-size: 2.4rem;
    }

    .esquerda .content-esquerda {

        padding: 1.5rem;
    }

    .direita-text {
        padding: 0 1.5rem;
    }

    #diferenciais .cards-sobre .card-sobre {
  
        min-width: 90%;
        max-width: 90%;
    }

    #valores .content-direita{
        gap: 20px;
    }


    .esquerda .content-esquerda{
        
        gap: 20px;
    }



}


@media screen and (max-width: 320px) {
  .esquerda h2, #valores h2, #diferenciais h2{
            font-size: 2rem;
    }
    #sobre .geral-about-us img {
        max-width: 200px;
        padding: 10px;
    
    }


    #diferenciais .cards-sobre img {
        max-width: 130px;
    }
}