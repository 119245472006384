#description-page {
    display: block;
    margin-block: 100px;
    margin-inline: auto;
    width: 85%;
    max-width: 1800px;
    
    .titulo-description {
        font-size: 2.5rem;
        color: var(--color-neutral-10);
        margin-bottom: 50px;
        text-align: center;
    }

    .titulo-description span{
        color: var(--color-primary-20);
    }
    
    .pergunta-description {
        text-align: start;
        font-weight: bold;
        font-size: 1.9rem;
        margin-bottom: 20px;
        color: var(--color-primary-60);
    }
    
    .resposta-description{
        font-size: 1.5rem;
        text-align: justify;
    }
    
    .resposta-description span{
        font-weight: bold;
    }

    
}



#description-conatainer-page {
    max-width: 1800px;
    margin: 0 auto;
    margin-block: 50px;
    
  }

  .container-description{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, #062e33, #000);
    color: var(--color-neutral-40);
    padding: 5px 50px;
    max-width: 90%;
    margin: 0 auto;
    box-shadow: 0  20px 40px var(--color-primary-40, 0.9);
    border-radius: 10px;
  }

  
  
  .description-container {
    margin-block: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  



  
  .description-img {
    width: 500px; /* Reduz o tamanho da imagem */
    border-radius: 7px;
    
  }
  
 
    .description-content {
        flex-basis: 60%;
        padding: 20px;
    }

    .description-container-title {
        font-size: 1em;
        color: var(--color-primary-20); /* Cor primária ajustada conforme variáveis */
    }

    #description-conatainer-page h1 {
        font-size: 1.8em;
        margin-bottom: 20px;
        color: var(--color-neutral-40); /* Cor de texto mais clara */
    }

    #description-conatainer-page p {
        font-size: 1em;
        margin-bottom: 20px;
        text-align: justify;
        color: var(--color-neutral-30); /* Cor de texto intermediária */
    }

    #description-conatainer-page p span {
      
        margin-bottom: 20px;
        color: var(--color-neutral-40); /* Cor de texto intermediária */
    }

    #description-conatainer-page button a {
      font-weight: bold;
      color: var(--color-neutral-40); /* Cor de texto intermediária */
  }
    

    #description-conatainer-page button{
        background: linear-gradient(to right, #00DBDE, #007BFF); /* Cor do botão ajustada */
        border: none;
        color: var(--color-neutral-40);
        padding: 15px 40px;
        text-align: center;
        border-radius: 5px;
        text-decoration: none;
        display: inline-blo ck;
        font-size: 16px;
        margin: 4px 0px;
        cursor: pointer;
        margin-bottom: 20px;
    }

    #description-conatainer-page button:hover {
        background-color: var(--color-primary-30); /* Efeito hover ajustado para uma cor primária mais clara */
        color: var(--color-neutral-40);
        box-shadow: 0 4px 20px rgba(255, 255, 255, 0.7);
    }



   

    @media screen and (max-width: 1630px) {
        #description-page {
            .titulo-description {
                font-size: 2.1rem;
            }

            .pergunta-description{
                font-size: 1.5rem;
            }
            .resposta-description{
                font-size: 1.2rem;
            }
        }

        #description-conatainer-page h1{
            font-size: 1.4rem;
        }


        #description-conatainer-page p {
            font-size: 0.9rem;
        }

        .description-img {
            width: 400px; 
        }

        #description-conatainer-page button {
            padding: 10px 30px;
        }

        .description-container {
            margin-block: 10px;
        }
    }
    
    @media screen and (max-width: 1230px) {
        #description-page {
            .titulo-description {
                font-size: 1.8rem;
            }

            .pergunta-description{
                font-size: 1.3rem;
            }
            .resposta-description{
                font-size: 1.1rem;
            }
        }

        #description-conatainer-page{
            margin-block: 25px;
        }

        #description-conatainer-page h1{
            font-size: 1.3rem;
        }


        #description-conatainer-page p {
            font-size: 0.88rem;
        }

        .description-img {
            width: 350px; 
        }

        #description-conatainer-page button {
            padding: 9px 25px;
        }

        .description-container {
            margin-block: 10px;
        }

        #description-page{
            margin-block: 60px;
        }
    }
    
    @media screen and (max-width: 1030px) {
        .description-container{
            flex-direction: column;
            padding: 2rem;
          }
          .description-img {
             
            width: 100%;
        
        }
    }


    @media screen and (max-width: 770px) {
        #description-conatainer-page button:hover {
            box-shadow: none;
        }
    
   
    
        #description-page {
            .titulo-description {
                font-size: 1.6rem;
            }
    
            .pergunta-description {
                font-size: 1.2rem;
            }
            .resposta-description {
                font-size: 1rem;
            }
        }
    
        #description-conatainer-page h1 {
            font-size: 1.2rem;
        }
    
        #description-conatainer-page p {
            font-size: 0.85rem;
        }
    
        #description-conatainer-page button {
            padding: 7px 20px;
        }

        .container-description {
            padding: 5px 10px;
        }

        .description-container {
            padding: 1.5rem;
        }

        
        .description-content {
           
            padding: 10px;
        }

    }
    

    @media screen and (max-width: 480px) {
        .description-container {
            flex-direction: column;
            gap: 20px;
            padding: 1rem;
        }
    
     
    
        #description-page {
            .titulo-description {
                font-size: 1.4rem;
            }
    
            .pergunta-description {
                font-size: 1rem;
            }
            .resposta-description {
                font-size: 0.9rem;
            }
        }
    
        #description-conatainer-page h1 {
            font-size: 1rem;
        }
    
        #description-conatainer-page p {
            font-size: 0.75rem;
        }
    
        #description-conatainer-page button {
            padding: 5px 15px;
            font-size: 0.75rem;
        }
    }
    
    @media screen and (max-width: 320px) {
        .description-img {
            width: 100%;
        }
    
        #description-page {
            .titulo-description {
                font-size: 1.2rem;
            }
    
            .pergunta-description {
                font-size: 0.9rem;
            }
            .resposta-description {
                font-size: 0.8rem;
            }
        }
    
        #description-conatainer-page h1 {
            font-size: 0.9rem;
        }
    
        #description-conatainer-page p {
            font-size: 0.7rem;
        }
    
        #description-conatainer-page button {
            padding: 3px 10px;
            font-size: 0.6rem;
        }
    }