#service-description {
    width: 85%;
    margin: 0 auto;
    padding: 10px;
    margin-top: 150px;
}

#service-description h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 100px;
}

#service-description h2 span {
    color: var(--color-primary-10);
}


#beneficios {
    width: 85%;
    margin: 0 auto;
    padding: 10px;
    margin-bottom: 100px;
    
}

#beneficios h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-top: 50px;
}

#beneficios h2 span {
    color: var(--color-primary-20);
}


#beneficios h4 {
    color: #ccc;
    text-align: center;
}

#beneficios h4.mobile-beneficios {
    display: none;
}


.container-beneficios {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    flex-wrap: wrap;
    margin-block: 100px;
}


@media screen and (max-width: 550px) {
    
    #service-description h2{
        font-size: 150%;
    }

    #beneficios h2{
        font-size: 200%;
    }

    .container-beneficios {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-block: 50px;
    }

}


@media screen and (max-width: 480px) {
    #beneficios h4.pc-beneficios {
        display: none;
    }
    #beneficios h4.mobile-beneficios {
        display: block;
    }

    
}

@media screen and (max-width: 376px) {

    #service-description h2{
        font-size: 120%;
    }

    #beneficios h2{
        font-size: 135%;
    }
}