.space-priv {
    padding-block: 50px;
}

main {
    height: 100%;
}

.section-privacidade {
    width: 70%;
    margin: 0 auto;
    padding-block: 100px;
}

.title-privacidade {
    font-size: 3rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.section-privacidade p, .section-privacidade ul {
    font-size: 1.2rem;
    text-align: justify;
}

.section-privacidade ul {
    padding-left: 60px;
    margin-bottom: 10px;
}

.section-privacidade h2 {
    font-size: 2rem;
    margin-top: 30px;
    margin-bottom: 10px;
}

/* Responsividade para diferentes tamanhos de tela */
@media screen and (max-width: 1630px) {
    .section-privacidade {
        width: 80%; /* Aumenta a largura em telas menores para melhor legibilidade */
    }
}

@media screen and (max-width: 1030px) {
    .section-privacidade {
        width: 90%;
    }
    .title-privacidade {
        font-size: 2.5rem; /* Diminui a fonte do título para adaptação em tablets */
    }
}

@media screen and (max-width: 770px) {
    .section-privacidade {
        padding-block: 50px; /* Reduz o padding para mais espaço */
    }
    .section-privacidade p, .section-privacidade ul {
        font-size: 1rem; /* Diminui a fonte para melhor leitura em dispositivos menores */
    }
}

@media screen and (max-width: 480px) {
    .section-privacidade {
        width: 80%; /* A seção ocupa toda a largura da tela em dispositivos móveis */
        padding-block: 30px;
    }
    .section-privacidade h2 {
        font-size: 1.5rem; /* Diminui o tamanho do título das seções para melhor ajuste */
    }
    .space-priv {
        padding-block: 0px;
    }
}

@media screen and (max-width: 320px) {
    .title-privacidade {
        font-size: 2rem; /* Ajuste final para telas muito pequenas */
    }
}
