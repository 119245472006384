.espaco-card {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 19%;  /* Ensure it's large enough to visibly contain the card and some space around */
    max-width: 19%;
    
}

.card-beneficios {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
   
    height: 250px;
    border-radius: 20px;
    
    perspective: 100px;
    position: relative;
    transition: transform 1.4s; /* Slower return transition for a smoother flip */
    transform-style: preserve-3d;
    color: white;
    
}

.espaco-card:hover .card-beneficios {
    transform: rotateY(180deg); /* Apply the rotation when hovering over espaco-card */
    transition: transform 1s; /* Faster transition when flipping */
}

.frente {
    position: relative;
    width: 100%;
    height: 100%; /* Essa propriedade garantirá que a 'frente' preencha o espaço designado. */
    backface-visibility: hidden; /* Oculta a frente quando o card é virado. */
    border-radius: 20px; /* Mantém a consistência com o design do card. */
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    box-shadow: 0 0 10px var(--color-primary-40);
}

.titulo-beneficios {
    position: absolute;
    height: 80px; /* Altura fixa do título */
    bottom: 0; /* Posiciona o título na parte inferior da div 'frente'. */
    width: 100%; /* Garante que o título se estenda por toda a largura. */
    text-align: center; /* Centraliza o título horizontalmente. */
    background: linear-gradient(to right, #007BFF, #00DBDE); /* Gradiente de fundo. */
    color: white; /* Define a cor do texto para branco. */
    display: flex; /* Utiliza Flexbox para alinhar o conteúdo. */
    align-items: center; /* Centraliza o conteúdo verticalmente. */
    justify-content: center; /* Centraliza o conteúdo horizontalmente. */
    padding: 10px; /* Adiciona padding para espaçar o texto. */
    box-sizing: border-box; /* Inclui o padding no cálculo da largura e altura. */
    border-radius: 0 0 20px 20px; /* Arredonda apenas os cantos inferiores. */
    font-weight: bold;
    
}


.tras {
    transform: rotateY(180deg);  /* Pre-rotates the back side 180 degrees */
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 12px;
    border-radius: 20px;
    background: linear-gradient(to right, #062e33, #000);
    
    box-shadow: 0 0 30px var(--color-primary-10);

}

.texto-beneficios {
    font-size: 1.05rem;
}
.texto-beneficios span{
    color: var(--color-primary-20);
}

@media screen and (max-width: 1630px) {
    .espaco-card {
    
        min-width: 25%;  /* Ensure it's large enough to visibly contain the card and some space around */
        max-width: 25%;
        
    }
}

@media screen and (max-width: 1230px) {
    .espaco-card {
    
        min-width: 30%;  /* Ensure it's large enough to visibly contain the card and some space around */
        max-width: 30%;
        
    }
}

@media screen and (max-width: 1030px) {
    .espaco-card {
    
        min-width: 34%;  /* Ensure it's large enough to visibly contain the card and some space around */
        max-width: 34%;
        
    }
}

@media screen and (max-width: 910px) {
    .espaco-card {
    
        min-width: 40%;  /* Ensure it's large enough to visibly contain the card and some space around */
        max-width: 40%;
        
    }
}

@media screen and (max-width: 770px) {
    .espaco-card {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        min-width: 90%;  /* Ensure it's large enough to visibly contain the card and some space around */
        max-width: 90%;
        
    }
}





@media screen and (max-width: 320px) {
    .espaco-card {

        min-width: 96%;  /* Ensure it's large enough to visibly contain the card and some space around */
        max-width: 96%;
        
    }
}


