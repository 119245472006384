/* CustomArrow.css */
.custom-arrow.slick-prev, 
.custom-arrow.slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    color: #000; /* Adjust the color as needed */
}
  
.custom-arrow.slick-prev {
left: -100px; /* Adjust the position as needed */
}

.custom-arrow.slick-next {
right: -100px; /* Adjust the position as needed */
}

/* Optional: Additional hover styles */
.custom-arrow.slick-prev:hover, 
.custom-arrow.slick-next:hover {
color: var(--color-primary-10); /* Example hover color */
}
  

@media screen and (max-width: 770px) {
    .custom-arrow.slick-prev {
        left: -70px; /* Adjust the position as needed */
        }
        
    .custom-arrow.slick-next {
    right: -70px; /* Adjust the position as needed */
    }
}

@media screen and (max-width: 320px) {
    .custom-arrow.slick-prev {
        left: -50px; /* Adjust the position as needed */
        }
        
    .custom-arrow.slick-next {
    right: -50px; /* Adjust the position as needed */
    }
}
