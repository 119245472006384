#solucoes-page {
    text-align: center; /* Alinha o texto do título e do parágrafo ao centro */
    padding: 20px; /* Adiciona um pouco de padding ao redor da seção */
    margin-block: 100px;
  }
  
.title-page-solucoes {
  width: 75%;
  margin: 0 auto;
}

.title-page-solucoes .services-content p {
  padding: 10px;
}
  
.solucoes-page-container {
  display: grid; /* Define o layout de grid para os cartões de serviço */
  grid-template-columns: repeat(2, 1fr); /* Divide o grid em 4 colunas de tamanho igual */
  gap: 20px; /* Espaço entre os cartões */
  padding: 20px; /* Padding ao redor do container para não colar nas bordas */
  justify-content: center; /* Centraliza os cartões no grid */
  width: 75%;
  margin: 0 auto;
}

.mobile-service {
  display: none;
}

.solucoes-page-container2 {
  display: grid; /* Define o layout de grid para os cartões de serviço */
  grid-template-columns: repeat(1, 1fr); /* Divide o grid em 4 colunas de tamanho igual */
  gap: 20px; /* Espaço entre os cartões */
  padding: 20px; /* Padding ao redor do container para não colar nas bordas */
  justify-content: center; /* Centraliza os cartões no grid */
  width: 37.5%;
  margin: 0 auto;
}


.services-content-page{
  width: 73%;
  margin: 0 auto;
  padding: 20px;
}
.services-content-page p , .services-content-page2 p {
  font-size: 1.5rem;
  
}

.services-content-page h2, .services-content-page2 h2{
  margin-bottom: 15px;
  font-size: 2.8rem;
  font-weight: 700;
}

.services-content-page h2 span, .services-content-page2 h2 span{
  color: var(--color-primary-10);
}


  
@media screen and (max-width: 1630px) {

  .solucoes-page-container {
    width: 90%;
  }

  .solucoes-page-container2  {
    width: 45%;
  }

}

@media screen and (max-width: 1270px) {
  .solucoes-page-container{
    grid-template-columns: repeat(1, 1fr); 
  }

  .mobile-service {
    display: block;
  }

  .solucoes-page-container2 {
    display: none;
  }
}

@media screen and (max-width: 770px){
  .services-content-page p, .services-content-page2 p {
    font-size: 1.2rem;
    
  }
}

@media screen and (max-width: 480px){
  .services-content-page p, .services-content-page2 p{
    font-size: 1rem;
  }
}