.contact-section {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), var(--color-neutral-0)), url("../utils/img/contacting.png");
    background-repeat: no-repeat;
    background-color: var(--color-primary-40);
    height: 400px;
    width: 80%;
    margin: auto;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    
    margin-bottom: 100px;
    box-shadow: 0  20px 40px var(--color-primary-30, 0.9);
}

.contact-image {
  display: none;
}

  .color-overlay {

    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  
  .contact-content {
    max-width: 50%;
  }
  
  .contact-content h2 {
    font-size: 2rem;
    color: var(--color-neutral-40); /* Cor do texto */
    
  }

  .contact-content h2 span {
    font-size: 2rem;
    color: var(--color-primary-10); /* Cor do texto */
    
  }
  
  .contact-content p {
    padding-right: 30px;
    font-size: 1rem;
    margin: 10px 0;
    color: var(--color-neutral-30); /* Cor do texto */
  }

  .contact-content p span {
    font-size: 1rem;
    margin: 10px 0;
    color: var(--color-neutral-40); /* Cor do texto */
  }

  
  
  .contact-content ul {
    list-style: none;
    padding: 0;
  }
  
  .v-middle{
    margin-right: 7px;
  }
  .contact-content ul li {
    margin: 10px 0;
    font-size: 1rem;
    color: var(--color-neutral-40); /* Cor do texto */
  }

  .check {
    color: var(--color-primary-30);
  }
  
  .contact-content button {
    
    background-color: #5cd9ff; /* Exemplo de cor, ajuste conforme necessário */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .contact-content button:hover {
    background-color: #49b4d6; /* Cor mais escura no hover */
    box-shadow: 0 0 20px var(--color-primary-10);
  }
  
  @media screen and (max-width: 1630px) {
    .contact-content {
        max-width: 60%; /* Allow more text space as screen narrows */
    }

    .contact-section {
     background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), var(--color-neutral-0)), url("../utils/img/contacting-responsive.png");
      
    }

}
@media screen and (max-width: 1030px) {
  .contact-section {
      flex-direction: column; /* Stack elements vertically */
      height: auto; /* Adjust height to accommodate content */
      padding: 20px; /* Add padding for better spacing */
      width: 90%; /* Increase width to utilize more screen space */
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.1), var(--color-neutral-0)), url("../utils/img/contacting-responsive-blur.png");
      background-position: right center; /* Position background image to the right */
      background-size: cover; /* Ensure background covers the available area */
      background-repeat: no-repeat; /* Do not repeat the background image */
  }

  .contact-content {
      max-width: 100%; /* Use full width for content */
      padding: 20px; /* Add padding to maintain layout */
  }
}

@media screen and (max-width: 770px) {
  .contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, var(--color-primary-40), var(--color-neutral-10));
    padding: 0;
    width: 90%;
    height: auto;
    border-radius: 10px;
    margin: 50px auto;
    box-shadow: 0  20px 40px var(--color-primary-30, 0.9);
}

.img {
  width: 100%;
  margin: auto;
  border-radius: 10px;
  background: cover;

}
.img {
  background-image: url("../utils/img/contacting-mobile.png");
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
  margin-bottom: 0px; /* Espaço entre a imagem e o texto do cartão */
  height: 400px; /* Altura fixa para as imagens dos cartões */
 
    
   
}
  .contact-content {
      width: 85%;  /* Use the full width for content */
      padding: 20px;  /* Increase padding for readability */
      margin-top: -100px;  /* Remove additional top margin */
  }

  

  .contact-content h2, .contact-content h2 span {
      font-size: 1.7rem;  /* Slightly reduce the font size for headings */
  }

  .contact-content p, .contact-content ul li {
      font-size: 0.9rem;  /* Reduce font size for paragraphs and list items */
  }

  .contact-content button {
      padding: 8px 16px;  /* Adjust button padding */
      font-size: 0.9rem;  /* Adjust button font size */
      width: auto;  /* Default width for buttons */
      margin-block: 10px;  /* Extra margin above the button */
  }

  .contact-content button:hover {
    background-color: #5cd9ff; /* Cor mais escura no hover */
    box-shadow: none;
  }
}

@media screen and (max-width: 480px) {
  .contact-content {
      padding: 10px;  /* Adjust padding */
  }

  .contact-content h2 {
      font-size: 1.5rem;  /* Reduce font size further */
  }

  .contact-content button {
      width: 100%;  /* Full width button for easier access */
  }

}

@media screen and (max-width: 320px) {
  .contact-content p, .contact-content ul li {
      font-size: 0.8rem;  /* Smallest font size for readability */
  }

  .contact-content button {
      font-size: 0.8rem;  /* Adjust button text size */
  }
}


.space {
  padding-block: 20px;
}