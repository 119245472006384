.text-cliente-page {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 1.2rem;
}


.clientes-page {
    margin-top: 50px;
    margin-bottom: 100px;
}

.clientes-page .text-cliente-page  h1 {
    text-align: center;
    font-size: 3rem;
}

.clientes-page .text-cliente-page  h1 span {
    color: var(--color-primary-10);
}

.text-cliente-page p {
    margin-top: 20px;
    font-size: 1.6rem;
    text-align: justify;
    margin-bottom: 100px;
}

.text-cliente-page p span{
    font-weight: bold;
}


.descricao-cliente {
    padding: 10px 30px;
    align-items: center;
    justify-content: center;

    text-align: default;
    
}

.descricao-cliente p {
    font-size: 1.05rem;
}

.descricao-cliente p span{
    
    font-weight: bold;
    color: var(--color-neutral-0);
}

.cards-clientes{
    
    display: grid; /* Define o layout de grid para os cartões de serviço */
    grid-template-columns: repeat(3, 1fr); /* Divide o grid em 4 colunas de tamanho igual */
    gap: 20px; /* Espaço entre os cartões */
    padding: 20px; /* Padding ao redor do container para não colar nas bordas */
    justify-content: center; /* Centraliza os cartões no grid */
    width: 90%;
    margin: auto auto;
    
}
.card-client-page {
    margin: auto;
    width: 90%;
    border-radius: 10px;
    height: 400px;
    margin-bottom: 40px;
    box-shadow: 0 0 10px var(--color-neutral-10);
    text-align: center;
    transition: 1s ease;
}

#clientes .img-cards img {
    
    width: 250px;
    height: auto;
}

#clientes img.pollysoft  {
    width: 175px;
}

.img-cards {
    height: 150px; /* Set the height to 150px */
    display: flex; /* Use flexbox to align img within the card */
    justify-content: center; /* Center the img horizontally */
    align-items: center; /* Center the img vertically */
    
}

.tipo-servico {
    text-align: center;
    
    margin-bottom: 20px;
}

.bi-s {
    background-color: gold;
    padding: 2px 10px;
    color: white;
    border-radius: 3px;
}

.supti-s {
    background-color: blue;
    padding: 2px 10px;
    color: white;
    border-radius: 3px;
}

.infra-s {
    background-color: green;
    padding: 2px 10px;
    color: white;
    border-radius: 3px;
}


.card-client-page:hover {
    transform: translateY(-10px);
    background: linear-gradient(to top, var(--color-primary-10), white);
    box-shadow: 0  20px 40px var(--color-primary-20);
    cursor:default;
    

  
 

  }

@media screen and (max-width:1630px) {
   

    .card-client-page {
        height: 410px;
    }
}


@media screen and (max-width: 1400px) {
    .cards-clientes {
        grid-template-columns: repeat(2, 1fr); /* Altera para 2 colunas em telas menores que 1630px */
        gap: 0px;

        
    }
    .card-client-page {
        width: 80%;
    }

    


}

@media screen and (max-width: 1030px) {
    .cards-clientes {
        grid-template-columns: repeat(1, 1fr); /* Altera para 1 coluna em telas menores que 1030px */
    }
    .card-client-page {
        height: auto; 
    }

    .descricao-cliente {
        margin-bottom: 15px;
    }

    .text-cliente-page p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 770px) {
    .card-client-page {
        width: 90%; /* Reduz a largura do cartão para ocupar mais espaço na tela */
    }

    .card-client-page:hover {
        
       
        transform: translateY(0px);
        
      
      }

      .card-client-page {
        background: linear-gradient(to top, var(--color-primary-10), white);
        box-shadow: 0  20px 40px var(--color-primary-20);
        cursor:default;
      }

      .text-cliente-page p {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 480px) {
    

    .cards-clientes {
        padding: 10px; /* Reduz o padding entre os cartões em telas menores */
    }

    .clientes-page .text-cliente-page h1 {
        font-size: 2rem; /* Reduz o tamanho do título em telas menores */
    }

    #clientes .img-cards img {
        width: 150px; /* Reduz o tamanho da imagem em telas menores */
    }
    .text-cliente-page p {
        font-size: 0.85rem;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 320px) {
    .clientes-page .text-cliente-page h1 {
        font-size: 2rem; /* Reduz ainda mais o tamanho do título em telas muito pequenas */
    }
}
